<!--  -->
<template>
  <div style="height: 100%;">
    <a-row style="height: 100%;">
      <a-col :span="4">
        <!-- 左边竖线样式写法 -->
        <div class="titleBox">
        <div class="titleLine" style=""></div>
          <div>网格列表</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            @node-click="treeClick"
            :filter-node-method="filterNode"
            ref="tree"
          >
          </el-tree>
        </div>
      </a-col>
      <a-col
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8; padding: 1%"
      >
        <ax-table
          ref="logTable"
          :columns="columns"
          :show-search="true"
          :showToolBar="false"
          :searchForm="searchForm"
          :dataSourceParams="dataSourceParams"
          :dataSourceApi="api.list"
        >
        </ax-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import api from "./api";
// import addGrid from "./components/addGrid";

const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "keyword",
    options: { placeholder: "请输入姓名/手机号" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "部门",
    type: "select",
    model: "gridRole",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  // components: {  },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "gridWorkerName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "账号",
            dataIndex: "id",
            ellipsis: false,
            width: 150,
          },
          {
            title: "手机号",
            dataIndex: "mobilePhone",
            ellipsis: true,
            width: 130,
          },
          {
            title: "现住区域",
            dataIndex: "gridName",
            ellipsis: false,
          },
          {
            title: "部门",
            dataIndex: "updateBy",
            ellipsis: true,
          },
          {
            title: "角色",
            dataIndex: "gridRole",
            ellipsis: true,
          },
          {
            title: "账号状态",
            dataIndex: "deleteFlag",
            ellipsis: true,
          },
        ],
        false,
      ),
      loading: false,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      dataSourceParams: { gridId: "" },
      // 左树
      filterText: "",
      data: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  computed: {},

  methods: {
    // 树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 树点击
    async treeClick(e) {
      if (e.value) {
        this.dataSourceParams.gridId = e.value;
        this.$refs.logTable.getDataSource();
        this.treeID = e.value;
      }
    },
    // 部门人员树
    async deptTree() {
      const res = await api.deptTree();
      this.GetreeArr(res.data);
    },
    GetreeArr(data) {
      data.forEach((item) => {
        if (item.phone === null) {
          item.disabled = true;
        }
        if (item.children && item.children.length > 0) {
          this.GetreeArr(item.children);
        }
      });
      this.$refs.form.setFormItemProp("gridWorkerId", {
        options: {
          treeData: data,
          allowClear: true,
        },
      });
    },
  },

  created() {},

  mounted() {
    this.getgridTree();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='less' scoped>
.titleBox{
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine{
    height: 0.09rem;
    width: 0.015rem;
    background-color:#2a5cff; 
    margin-right: 5px;
  }
}


.filter-tree {
  margin-top: 2%;
}

.gridConfig {
  width: 100%;
  height: 0.2rem;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #556bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #556bff;
  cursor: default;
}

/deep/.is-current {
  color: #556bff !important;
}
/deep/.el-tree-node__content {
  height: 0.16rem;
}
/deep/.el-tree-node__content {
  &:hover {
    background-color: #e8eef4;
  }
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  background-color: #e8eef4 !important;
}
// /deep/.el-tree-node__label {
//       font-size: 0.07rem !important;
//     }

/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>